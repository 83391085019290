import type { NextPage } from 'next'
import { useRouter } from 'next/router'
import { useEffect } from 'react'
import { useAuth, AuthStatus } from '../contexts/AuthContext'

const Home: NextPage = () => {
  const router = useRouter()
  const { status } = useAuth()

  useEffect(() => {
    if (status == AuthStatus.SignedIn) {
      router.push('/home')
    }
  }, [status])

  return null
}

export default Home
